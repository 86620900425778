import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputFormRadio,
} from 'components';
import { useNavigate, useParams } from 'react-router';
import { RiArrowLeftLine, RiArrowRightLine, RiFile2Line } from 'react-icons/ri';
import { getDetailProposal } from 'services/danaProgram/callForProposalService';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { getUrlFile } from 'services/danaProgram/fileService';

const BeneficiariesDetail = ({ onNext, onPrev }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  let { id } = useParams();
  let methods = useForm();

  const fetchDetailCfp = async (params) => {
    const res = await getDetailProposal(id, params);
    setData(res.data.data);
  };

  useEffect(() => {
    fetchDetailCfp({ category: 'beneficiaries' });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      methods.reset({
        indigenous_communities: data?.indigenous_communities,
        traditional_law_communities: data?.traditional_law_communities,
        vulnerable_communities: data?.vulnerable_communities,
      });
    }
  }, [data]);

  const downloadFile = async (item) => {
    const res = await getUrlFile({
      identifier: 'id',
      value: item?.fileId,
    });
    window.open(res?.data?.data?.url, '_blank');
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <div>Detail</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col space-y-5">
              <div className="flex flex-col space-y-5">
                <div className="flex flex-col">
                  <InputFormRadio
                    controllerName={'indigenous_communities'}
                    label="Apakah kegiatan anda melibatkan beneficiaries dari kalangan masyarakat adat?"
                    disabled
                    values={[
                      {
                        label: 'Ya',
                        value: true,
                      },
                      {
                        label: 'Tidak',
                        value: false,
                      },
                    ]}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="label font-semibold font-[14px] ">
                    <span className={`label-text`}>
                      masyarakat adat yang dilibatkan tersebut
                    </span>
                  </label>
                  <label className="text-[#1D2939]">
                    {data?.indigenous_communities_description ?? '-'}
                  </label>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {data?.indigenous_communities_document.length > 0 ? (
                    data?.indigenous_communities_document.map((item, index) => {
                      return (
                        <div
                          key={`indigenous_communities_document-${index}`}
                          className="border p-4 rounded-2xl"
                        >
                          <div className="flex gap-4">
                            <div>
                              <div className="bg-[#EEFFF4] rounded-full p-4">
                                <RiFile2Line className="text-primary-600" />
                              </div>
                            </div>
                            <div className="flex flex-col gap-2">
                              <span className="font-medium">
                                {item.fileName}
                              </span>
                              <span>{item.fileSize}</span>
                              <button
                                className="text-left"
                                onClick={() => downloadFile(item)}
                              >
                                <span className="font-semibold text-primary-600">
                                  Download file
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="flex flex-col space-y-5">
                <div className="flex flex-col">
                  <InputFormRadio
                    controllerName={'traditional_law_communities'}
                    label="Apakah kegiatan anda melibatkan beneficiaries dari kalangan masyarakat hukum adat tertentu?"
                    disabled
                    values={[
                      {
                        label: 'Ya',
                        value: true,
                      },
                      {
                        label: 'Tidak',
                        value: false,
                      },
                    ]}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="label font-semibold font-[14px] ">
                    <span className={`label-text`}>
                      masyarakat adat yang dilibatkan tersebut
                    </span>
                  </label>
                  <label className="text-[#1D2939]">
                    {data?.traditional_law_communities_description ?? '-'}
                  </label>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {data?.traditional_law_communities_document.length > 0 ? (
                    data?.traditional_law_communities_document.map(
                      (item, index) => {
                        return (
                          <div
                            key={`traditional_law_communities_document-${index}`}
                            className="border p-4 rounded-2xl"
                          >
                            <div className="flex gap-4">
                              <div>
                                <div className="bg-[#EEFFF4] rounded-full p-4">
                                  <RiFile2Line className="text-primary-600" />
                                </div>
                              </div>
                              <div className="flex flex-col gap-2">
                                <span className="font-medium">
                                  {item.fileName}
                                </span>
                                <span>{item.fileSize}</span>
                                <button
                                  className="text-left"
                                  onClick={() => downloadFile(item)}
                                >
                                  <span className="font-semibold text-primary-600">
                                    Download file
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="flex flex-col space-y-5">
                <div className="flex flex-col">
                  <InputFormRadio
                    controllerName={'vulnerable_communities'}
                    label="Apakah kegiatan anda melibatkan beneficiaries dari perempuan dan kelompok rentan?"
                    disabled
                    values={[
                      {
                        label: 'Ya',
                        value: true,
                      },
                      {
                        label: 'Tidak',
                        value: false,
                      },
                    ]}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="label font-semibold font-[14px] ">
                    <span className={`label-text`}>
                      masyarakat adat yang dilibatkan tersebut
                    </span>
                  </label>
                  <label className="text-[#1D2939]">
                    {data?.vulnerable_communities_description ?? '-'}
                  </label>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {data?.vulnerable_communities_document.length > 0 ? (
                    data?.vulnerable_communities_document.map((item, index) => {
                      return (
                        <div className="border p-4 rounded-2xl">
                          <div className="flex gap-4">
                            <div>
                              <div className="bg-[#EEFFF4] rounded-full p-4">
                                <RiFile2Line className="text-primary-600" />
                              </div>
                            </div>
                            <div className="flex flex-col gap-2">
                              <span className="font-medium">
                                {item.fileName}
                              </span>
                              <span>{item.fileSize}</span>
                              <button
                                className="text-left"
                                onClick={() => downloadFile(item)}
                              >
                                <span className="font-semibold text-primary-600">
                                  Download file
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <label className="text-[#1D2939] font-bold">
                Berapa jumlah beneficiaries yang dilibatkan dalam kegiatan ini?
              </label>
              <div className="flex flex-col">
                <div className="flex w-full">
                  <span className="flex-1 font-bold">Jumlah Perempuan</span>
                  <span className="flex-1 font-bold">Jumlah Laki-Laki</span>
                  <span className="flex-1 font-bold">Total</span>
                </div>
                <div className="flex">
                  <span className="flex-1">{data?.total_woman}</span>
                  <span className="flex-1">{data?.total_man}</span>
                  <span className="flex-1">{data?.total_beneficiaries}</span>
                </div>
              </div>
              {data?.beneficiaries.length > 0 ? (
                data?.beneficiaries.map((item, index) => {
                  return (
                    <CardForm
                      key={`beneficiaries-${index}`}
                      label={`Beneficiaries ${index + 1}`}
                      buttonCollapse={true}
                    >
                      <div className="flex flex-col">
                        <div className="flex w-full">
                          <span className="flex-1 font-bold">
                            Nama Kelompok
                          </span>
                          <span className="flex-1 font-bold">
                            Ketua Kelompok
                          </span>
                          <span className="flex-1 font-bold">
                            Tipe Beneficiaries
                          </span>
                        </div>
                        <div className="flex">
                          <span className="flex-1">{item?.community_name}</span>
                          <span className="flex-1">
                            {item?.community_leader}
                          </span>
                          <span className="flex-1">
                            {item?.beneficiaries_type.label}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex w-full">
                          <span className="flex-1 font-bold">
                            Tahun terbit izin
                          </span>
                          <span className="flex-1 font-bold">
                            Jenis perizinan
                          </span>
                          <span className="flex-1 font-bold">
                            Status izin perhutanan sosial
                          </span>
                        </div>
                        <div className="flex">
                          <span className="flex-1">
                            {item?.permission_year || '-'}
                          </span>
                          <span className="flex-1">
                            {item?.permission_type?.label || '-'}
                          </span>
                          <span className="flex-1">
                            {item?.forestry_permit_status?.label || '-'}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex w-full">
                          <span className="flex-1 font-bold">
                            Luasan lahan yang diizinkan
                          </span>
                          <span className="flex-1 font-bold">
                            jumlah perempuan
                          </span>
                          <span className="flex-1 font-bold">
                            jumlah laki - laki
                          </span>
                          <span className="flex-1 font-bold">total </span>
                        </div>
                        <div className="flex">
                          <span className="flex-1">
                            {item?.permitted_land_area}
                          </span>
                          <span className="flex-1">{item?.total_woman}</span>
                          <span className="flex-1">{item?.total_man}</span>
                          <span className="flex-1">
                            {item?.total_beneficiaries}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="flex-1 font-bold">
                          Kegiatan ekonomi/ lingkungan yang sudah dilakukan
                          sejak terbitnya izin
                        </span>
                        {item.activities.length > 0 ? (
                          item.activities.map((itemActivity, indexActivity) => {
                            return (
                              <span
                                key={`beneficiaries-${index}-items-${indexActivity}`}
                                className="flex-1"
                              >
                                - {itemActivity.activity}
                              </span>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </CardForm>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </CardBody>
        </Card>
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={onPrev}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => onNext()}
            >
              Selanjutnya
              <RiArrowRightLine className="text-white" />
            </Button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default BeneficiariesDetail;
