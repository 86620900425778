import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";
import moment from "moment";

const token = localStorage.getItem("token");

export const getFinancialProfile = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/financial-profile/findById/${params}`
  );
};
export const financialProfileSubmit = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/financial-profile/verification`,
    params
  );
};

export const getProgramExperience = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/experience-programme/findById/${params}`
  );
};

export const programExperienceSubmit = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/experience-programme/verification`,
    params
  );
};

export const getSafeguards = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/safeguards/findById/${params}`
  );
};

export const safeguardsSubmit = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/safeguards/verification`,
    params
  );
};

export const getProposalNonSubmissionList = () => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/perhimpunan-dana/v1/proposal-non-submission`
  );
};

export const getProjectManagementTeamList = (params) => {
  return axiosInstance.get(
    `${
      config.BASE_URL
    }/api/dana-program/v1/cms/project-management-team/list?limit=${
      params?.limit
    }&page=${params?.page}${
      params?.searchKey !== null ? `&search=${params?.searchKey}` : ""
    }${params?.orderBy !== null ? `&order_by=${params?.orderBy}` : ""}${
      params?.orderMethod !== null ? `&order_method=${params?.orderMethod}` : ""
    }`
  );
};
export const getProjectManagementTeam = (proposalId) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-management-team?proposal_id=${proposalId}`
  );
};
export const submitProjectManagementTeam = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-management-team/assign`,
    params
  );
};

export const getActivityReportList = async (params) => {
  try {
    const data = await axios.get(
      `${
        config.BASE_URL
      }/api/dana-program/v1/cms/activity-report/review/list?limit=${
        params?.limit
      }&page=${params?.page}${
        params?.searchKey !== undefined ? `&search=${params?.searchKey}` : ""
      }${
        params?.date !== undefined &&
        params?.date !== null &&
        Array.isArray(params?.date)
          ? `&start_date=${moment(params?.date[0]).format(
              "DD%2FMM%2FYYYY"
            )}&end_date=${moment(params?.date[1]).format("DD%2FMM%2FYYYY")}`
          : ""
      }${params?.orderBy !== undefined ? `&order_by=${params?.orderBy}` : ""}${
        params?.orderMethod !== undefined
          ? `&order_method=${params?.orderMethod}`
          : ""
      }${params?.status !== undefined ? `&status=${params?.status}` : ""}`,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          common: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      }
    );

    return data;
  } catch (error) {
    return error.response;
  }
};

export const getActivityReport = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/activity-report/review/detail?id=${id}`
  );
};
export const getProgressReportList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/progress-report/list?limit=${
      params?.limit
    }&page=${params?.page}${
      params?.searchKey !== undefined ? `&search=${params?.searchKey}` : ""
    }${
      params?.startDate !== undefined && params?.startDate !== null
        ? `&start_date=${params?.startDate} `
        : ""
    }${
      params?.endDate !== undefined && params?.endDate !== null
        ? `&end_date=${params?.endDate} `
        : ""
    }${params?.orderBy !== undefined ? `&order_by=${params?.orderBy}` : ""}${
      params?.orderMethod !== undefined
        ? `&order_method=${params?.orderMethod}`
        : ""
    }${params?.status !== undefined ? `&status=${params?.status}` : ""}`
  );
};
export const getProgressReportReviewList = (params) => {
  return axiosInstance.get(
    `${
      config.BASE_URL
    }/api/dana-program/v1/cms/progress-report/review/list?limit=${
      params?.limit
    }&page=${params?.page}${
      params?.searchKey !== undefined ? `&search=${params?.searchKey}` : ""
    }${
      params?.startDate !== undefined && params?.startDate !== null
        ? `&start_date=${params?.startDate} `
        : ""
    }${
      params?.endDate !== undefined && params?.endDate !== null
        ? `&end_date=${params?.endDate} `
        : ""
    }${params?.orderBy !== undefined ? `&order_by=${params?.orderBy}` : ""}${
      params?.orderMethod !== undefined
        ? `&order_method=${params?.orderMethod}`
        : ""
    }${params?.status !== undefined ? `&status=${params?.status}` : ""}`
  );
};

export const getProgressReport = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/progress-report/detail?id=${id}`
  );
};

export const getProgressReportReview = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/progress-report/review/detail?id=${id}`
  );
};

export const submitProgressReport = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/progress-report/review/submit`,
    params
  );
};

export const addProgressReport = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/progress-report/submit`,
    params
  );
};

export const submitActivityReportStep1 = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/activity-report/review/step-one`,
    params
  );
};
export const submitActivityReportStep2 = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/activity-report/review/step-two`,
    params
  );
};

export const getDashboardTotalCommitment = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/total-commitment?${
      params?.start_date !== undefined && params?.start_date !== null
        ? `start_date=${params?.start_date} `
        : ""
    }${
      params?.end_date !== undefined && params?.end_date !== null
        ? `&end_date=${params?.end_date} `
        : ""
    }`
  );
};

export const getDashboardTotalReportedFund = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/total-reported-fund?${
      params?.start_date !== undefined && params?.start_date !== null
        ? `start_date=${params?.start_date} `
        : ""
    }${
      params?.end_date !== undefined && params?.end_date !== null
        ? `&end_date=${params?.end_date} `
        : ""
    }`
  );
};

export const getDashboardTotalDonor = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/total-donor`
  );
};

export const getDashboardCfpConceptNotes = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/cfp-conceptnotes`
  );
};

export const getDashboardUserBeneficiaries = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/user-beneficiaries`
  );
};

export const getDashboardProjectTeams = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/project-teams`
  );
};

export const getDashboardTotalDistributionMechanism = () => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/total-distribution-mechanism`
  );
};

export const getDashboardDetailDistribution = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/dashboard/detail-distribution?${
      params?.fund_source !== undefined && params?.fund_source !== null
        ? `fund_source=${params?.fund_source} `
        : ""
    }`
  );
};
