import { Button, Card, CardBody, CardHeader, InputForm } from "components";
import ActivityTarget from "./ArrayField/ActivityTarget";
import Resources from "./ArrayField/Resources";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useTorMutations } from "../../hooks/useTorMutations";
import { useCreateStore } from "pages/Tor/Create/store";
import useTimeWorkPlan from "../../../../../RencanaKerjaWaktu/hooks/useTimeWorkPlan";
import { useEffect, useState } from "react";
import AtomSelect from "components/atoms/Select";
import { useRecapMutations } from "../../hooks/useRecapMutations";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  work_plan_id: yup.string().required("Nama Proyek harus diisi"),
  work_plan_name: yup.object().required("Nama Proyek harus diisi"),
  awp_activity_id: yup.string().required("Kegiatan Utama harus diisi"),
  awp_activity_name: yup.string().required("Kegiatan Utama harus diisi"),
  output_id: yup.string().required("Output harus diisi"),
  output_name: yup.string().required("Output harus diisi"),
  background: yup.string().required("Latar Belakang harus diisi"),
  purpose: yup.string().required("Maksud dan tujuan harus diisi"),
  activity_targets: yup.array().of(
    yup.object().shape({
      target: yup.string().required("Target harus diisi"),
      total: yup.string().required("Total harus diisi"),
    })
  ).min(1, "Target harus diisi"),
  fund_resources: yup.array().of(
    yup.object().shape({
      awp_detail_activity_id: yup.string().required("Detail kegiatan harus diisi"),
      awp_detail_activity_name: yup.object().required("Detail kegiatan harus diisi"),
      remaining_limit: yup.string().required("Sisa Limit harus diisi"),
    })
  ).min(1, "Sumber Daya harus diisi"),
});

const TorForm = ({
  setSavedStep,
  isEdit,
  activityDetails,
  setActivityDetail,
  details,
}) => {
  const { step, nextStep, prevStep, setTorId } = useCreateStore();
  let isCreate = useLocation()?.pathname.includes("create");
  const userSelector = useSelector((state) => state?.auth?.user || {});
  const navigate = useNavigate();
  const TORID = sessionStorage.getItem("torId");
  const { submitMutation } = useTorMutations();
  const [projectArr, setProjectArr] = useState();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      activity_targets: [
        {
          target: null,
          total: null,
        },
      ],
      fund_resources: [
        {
          awp_detail_activity_id: null,
          awp_detail_activity_name: null,
          remaining_limit: null,
        },
      ],
    },
  });

  const {
    formState: { errors, isValid },
  } = methods;

  const handleSubmitForm = async (type, data) => {
    try {
      const detailParams = {
        id: TORID ?? null,
        user_id: userSelector?.UserId,
        ...data,
        fund_resources: data.fund_resources.map((item) => ({
          awp_detail_activity_id: item.awp_detail_activity_name?.value,
          awp_detail_activity_name: item.awp_detail_activity_name?.label,
          remaining_limit: item.remaining_limit,
        })),
        work_plan_name: data.work_plan_name?.label,
      };

      submitMutation.mutate(detailParams, {
        onSuccess: (res) => {
          if (type === "next") {
            if (isCreate && res.data.data._id) {
              setTorId(res.data.data._id);
              sessionStorage.setItem("torId", res.data.data._id ?? '');
            }
            sessionStorage.setItem("step", parseInt(step) + 1);
            nextStep();
          } else {
            navigate("/tor");
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const params = {
    page: 1,
    limit: 1000,
  };

  const { timeWorkPlanData, refetch } = useTimeWorkPlan({ params });

  useEffect(() => {
    methods.register("work_plan_id");
    methods.register("awp_activity_id");
    methods.register("output_id");

    refetch();
  }, []);

  useEffect(() => {
    setProjectArr(
      timeWorkPlanData.map((data) => ({
        value: data?._id,
        label: `${data?.awp_project_name} - ${data?.work_plan_name}`,
        ...data,
      }))
    );
  }, [timeWorkPlanData]);

  useEffect(() => {
    if (TORID && projectArr?.length) {
      const workplan = projectArr?.find(
        (data) => data?.value === details?.tor?.work_plan_id
      );

      methods.reset({
        ...details?.tor,
        work_plan_name: workplan,
        fund_resources: details?.tor?.fund_resources?.map((item) => ({
          ...item,
          awp_detail_activity_name: {
            label:
              // handle legacy data
              typeof item?.awp_detail_activity_name === 'object'
                ? item?.awp_detail_activity_name?.label
                : item?.awp_detail_activity_name,
            value: item?.awp_detail_activity_id,
          },
        })),
      });

      const activityDetails = workplan?.awp_detail_activities?.map((data, index) => ({
        label: data?.awp_detail_activity_name,
        value: data?.awp_detail_activity_id,
        ...workplan.awp_detail_activities?.[index],
      }));

      setActivityDetail(activityDetails);
    }
  }, [details, projectArr]);

  function onChangeWorkplan(selected) {
    methods.setValue("work_plan_id", selected?.value);
    methods.setValue("awp_activity_id", selected?.awp_project_activity_id);
    methods.setValue("awp_activity_name", selected?.awp_project_activity_name);
    methods.setValue("output_id", selected?.awp_output_id);
    methods.setValue("output_name", selected?.awp_output_name);
    methods.setValue("fund_resources", [{
      awp_detail_activity_id: null,
      awp_detail_activity_name: null,
      remaining_limit: null,
    }]);

    let activityDetails = selected?.awp_detail_activities?.map((data, index) => ({
      label: data?.awp_detail_activity_name,
      value: data?.awp_detail_activity_id,
      ...selected.awp_detail_activities?.[index],
    }));

    setActivityDetail(activityDetails);
  }

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-6">
        <Card>
          <CardHeader>Informasi Proyek</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-5">
              <AtomSelect
                controllerName={`work_plan_name`}
                className={`py-2 w-full rounded-md focus-within:border-primary-700`}
                label={"Nama Proyek"}
                placeholder={"Pilih Project"}
                options={projectArr || []}
                required={true}
                disable={!isEdit}
                onChange={onChangeWorkplan}
              />
              <InputForm
                controllerName={"awp_activity_name"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Kegiatan Utama yang diusulkan (berdasarkan AWP)"}
                placeholder={"Pilih Kegiatan"}
                required={true}
                disabled
              />
              <InputForm
                controllerName={"output_name"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Output Proyek"}
                placeholder={"Output Kegiatan AWP"}
                disabled={true}
              />
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Tor Kegiatan</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-5">
              <InputForm
                controllerName={"background"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Latar Belakang"}
                placeholder={"Latar Belakang"}
                required={true}
                disabled={!isEdit}
                textArea={true}
              />
              <InputForm
                controllerName={"purpose"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Maksud Dan Tujuan"}
                placeholder={"Maksud Dan Tujuan"}
                required={true}
                textArea={true}
                disabled={!isEdit}
              />
              <ActivityTarget methods={methods} disabled={!isEdit} />
              <Resources
                methods={methods}
                disabled={!isEdit}
                activityDetails={activityDetails}
              />
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
        <div>
          <Button
            type="button"
            className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
            onClick={() => {
              navigate("/tor");
              sessionStorage.removeItem("step");
            }}
          >
            Batal
          </Button>
        </div>

        <div className="items-center justify-between block gap-2 md:flex">
          {isEdit && (
            <Button
              type="button"
              className="text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => handleSubmitForm("draft")}
            >
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit((data) => handleSubmitForm("next", data))}
          >
            {submitMutation.isLoading ? "Tunggu Sebentar" : "Selanjutnya"}
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default TorForm;
