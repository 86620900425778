import { AtomButton, Button, InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import { convertMoney, deconvertMoney, generateUUID } from "helpers";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { CgChevronDown } from "react-icons/cg";
import { RiAddLine, RiDeleteBin6Line, RiPencilFill } from "react-icons/ri";

const Items = ({
  setTotalFunding,
  methods,
  disabled,
  TORID,
  datas,
  activityDetails,
  budgetAccountOptions,
}) => {
  const [show, setShow] = useState({
    child: true,
  });

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items.required_items",
  });

  const onChangeItem = (e, key, method) => {
    const updateSubtotals = () => {
      fields.forEach((field, index) => {
        if (method !== "remove") {
          let v = methods.getValues(`items.required_items.${index}.quantity`);
          let c = methods.getValues(`items.required_items.${index}.cost`);

          const quantity = v !== undefined && v !== "" ? deconvertMoney(v) : 1;
          const cost = c !== undefined && c !== "" ? deconvertMoney(c) : 1;
          const subTotal = quantity * cost;

          methods.setValue(
            `items.required_items.${index}.subtotal`,
            convertMoney(subTotal || "0")
          );
        } else {
          let v = methods.getValues(
            `items.required_items.${index !== key && index}.quantity`
          );
          let c = methods.getValues(
            `items.required_items.${index !== key && index}.cost`
          );

          const quantity = v !== undefined && v !== "" ? deconvertMoney(v) : 1;
          const cost = c !== undefined && c !== "" ? deconvertMoney(c) : 1;
          const subTotal = quantity * cost;

          methods.setValue(
            `items.required_items.${index !== key && index}.subtotal`,
            convertMoney(subTotal || "0")
          );
        }
      });

      const otherAssets = methods.watch(`items.required_items`) || [];
      const otherAssetSum = otherAssets
        .map((item) => parseInt(deconvertMoney(item?.subtotal || "0")))
        .reduce((sum, subTotal) => sum + subTotal, 0);

      const formattedOtherAssetSum = convertMoney(otherAssetSum);
      methods.setValue("items.total_cost", formattedOtherAssetSum);
      methods.setValue("items.total_estimated_cost", formattedOtherAssetSum);
      setTotalFunding(formattedOtherAssetSum);
    };
    updateSubtotals();
  };

  const detailOptions = useCallback((selected) => {
    const origin = activityDetails ?? [];

    return origin.map((item) => {
      const isDisabled = selected.some(
        (selectedItem) => selectedItem.detail_activity_name?.value === item.value
      );

      return {
        ...item,
        isDisabled,
      };
    });
  }, [activityDetails, methods.watch('items.required_items')]);

  return (
    <div className="flex flex-col gap-5">
      <div className={`flex rounded-lg border-[1px]`}>
        <div className={`flex flex-col w-full`}>
          <div
            className={`flex justify-between px-6 py-[11px] items-center bg-[#E1F1D6] rounded-md`}
          >
            <div className="flex">
              <span className={"text-[#01A24A] font-semibold text-lg"}>
                Daftar Item Kebutuhan
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-white flex items-center text-xs px-2 py-0.5 rounded-full bg-[#12B76A]">
                Total Kebutuhan :{" "}
                <b className="pl-1"> Rp. {methods.watch("items.total_cost")}</b>
              </div>
              <button onClick={() => setShow({ ...show, child: !show.child })}>
                <CgChevronDown
                  className={`text-2xl text-primary-700 ${
                    !show.child && "rotate-180"
                  }`}
                />
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full divide-y">
            {show.child &&
              (methods.watch('items.required_items') || []).map((field, key) => (
                <div key={field.id} className="flex flex-col gap-4 px-6 py-5">
                  {/* <div className="flex justify-end gap-3 text-end">
                <Button
                  type="button"
                  className="p-3 px-4 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                >
                  Pilih Item
                </Button>
                <button
                  className={`flex-none p-3 px-4 bg-[#D92D20] text-white rounded-md flex justify-center items-center`}
                >
                  <RiDeleteBin6Line />
                </button>
              </div> */}
                  <InputForm
                    controllerName={`items.required_items.${key}.item_name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={"Item Kebutuhan"}
                    placeholder={"Item Kebutuhan"}
                    required={false}
                    disabled={disabled}
                  />
                  <div className="flex gap-6">
                    <InputForm
                      controllerName={`items.required_items.${key}.quantity`}
                      forceNumber
                      className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                      label={"Quantity"}
                      placeholder={"Quantity"}
                      required={false}
                      onChangeInput={(e) => {
                        methods.setValue(
                          `items.required_items.${key}.quantity`,
                          convertMoney(e.target.value),
                          { shouldValidate: true }
                        );
                        onChangeItem(e, key);
                      }}
                      disabled={disabled}
                    />

                    <InputForm
                      controllerName={`items.required_items.${key}.cost`}
                      forceNumber
                      className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                      label={"Satuan Biaya (Rp)"}
                      placeholder={"Satuan Biaya (Rp)"}
                      required={false}
                      onChangeInput={(e) => {
                        methods.setValue(
                          `items.required_items.${key}.cost`,
                          convertMoney(e.target.value),
                          { shouldValidate: true }
                        );
                        onChangeItem(e, key);
                      }}
                      disabled={disabled}
                    />
                    <InputForm
                      controllerName={`items.required_items.${key}.subtotal`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                      label={"Sub Total (Rp)"}
                      placeholder={"Sub Total (Rp)"}
                      required={false}
                      disabled
                    />
                  </div>
                  <AtomSelect
                    className={`py-2 w-full rounded-md focus-within:border-primary-700`}
                    options={detailOptions(methods.watch('items.required_items'))}
                    controllerName={`items.required_items.${key}.detail_activity_name`}
                    label={"Detail Kegiatan"}
                    placeholder={"Detail Kegiatan"}
                    required={false}
                    disable={disabled}
                    onChange={(e) => methods.setValue(`items.required_items.${key}.budget_account_name`, null)}
                  />
                  <div className="flex items-end gap-3">
                    <AtomSelect
                      className={`py-2 w-full rounded-md focus-within:border-primary-700`}
                      options={field?.detail_activity_name?.budget_account}
                      controllerName={`items.required_items.${key}.budget_account_name`}
                      label={"Budget Account"}
                      placeholder={"Budget Account"}
                      required={false}
                      disable={disabled}
                    />
                    {key !== 0 && !disabled && (
                      <button
                        onClick={() => {
                          remove(key);
                          onChangeItem(0, key, "remove");
                        }}
                        className={`flex-none text-xl p-3 px-4 bg-[#D92D20] h-max text-white rounded-md flex justify-center items-center`}
                      >
                        <RiDeleteBin6Line />
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="text-end">
        <AtomButton
          theme="ghost"
          onClick={() =>
            append({
              item_id: generateUUID(),
              item_name: null,
              quantity: null,
              cost: null,
              subtotal: null,
              detail_activity_id: generateUUID(),
              detail_activity_name: null,
              budget_account_id: generateUUID(),
              budget_account_name: null,
            })
          }
          disabled={disabled}
          className="p-4 normal-case border border-gray-300 rounded-lg"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah item kebutuhan
            </div>
          }
        />
      </div>

      {/* end */}
    </div>
  );
};

export default Items;
