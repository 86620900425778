import { AtomButton, Button, Card, CardBody, CardHeader } from "components";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  RiAddLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiSave3Line,
} from "react-icons/ri";
import ResourcePerson from "./ArrayField/ResourcePerson";
import AddResourcePersonModal from "./Modals/AddResourcePersonModal";
import { useNavigate } from "react-router";
import { useCreateStore } from "pages/Tor/Create/store";
import Participant from "./ArrayField/Participant";
import Items from "./ArrayField/Items";
import { useRabMutations } from "../../hooks/useRabMutations";
import { AuthContext } from "providers/authProvider";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useRecapMutations } from "../../hooks/useRecapMutations";
import { useQuery } from "@tanstack/react-query";
import { getSpecificTimeWorkPlanDetail } from "services/danaProgram/specificTimeWorkPlanService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const subList = [
  { id: 1, label: "RAB Narasumber" },
  { id: 2, label: "RAB Peserta" },
  { id: 3, label: "RAB Barang" },
];

function isNumber() {
  return this
    .min(1, "Harus diisi")
    .required("Harus diisi")
    .matches(/^\d{1,3}(\.\d{3})*$/, {
      message: "Hanya boleh angka",
      excludeEmptyStrings: true
    });
}

yup.addMethod(yup.string, "isNumber", isNumber);

const RabForm = ({ setSavedStep, setStep, isEdit }) => {
  const userSelector = useSelector((state) => state?.auth?.user || {});
  const { step, nextStep, prevStep, torId } = useCreateStore();
  const navigate = useNavigate();
  const [activeList, setActiveList] = useState(1);
  const [showModal, setShowModal] = useState({
    personModal: false,
  });
  const [detailActivities, setDetailActivities] = useState([]);
  const TORID = sessionStorage.getItem("torId");
  const [totalFunding, setTotalFunding] = useState(0);

  const schema = yup.object().shape({
    resource: yup.mixed().when("activeList", {
      is: 1,
      then: () => yup.object().shape({
        total_person: yup.string().isNumber().required("Total person harus diisi"),
        total_estimated_cost: yup.string().required("Total estimasi biaya harus diisi"),
        required_items: yup.array().of(
          yup.object().shape({
            item_name: yup.string().required("Nama item harus diisi"),
            quantity: yup.string().isNumber().required("Jumlah barang harus diisi"),
            cost: yup.string().isNumber().required("Harga barang harus diisi"),
            subtotal: yup.string().required("Subtotal harus diisi"),
            detail_activity_name: yup.object().required("Nama kegiatan harus diisi"),
            budget_account_name: yup.object().required("Nama akun anggaran harus diisi"),
          })
        ),
      }),
    }),
    participant: yup.mixed().when("activeList", {
      is: 2,
      then: () => yup.object().shape({
        total_person: yup.string().isNumber().required("Total person harus diisi"),
        total_estimated_cost: yup.string().required("Total estimasi biaya harus diisi"),
        required_items: yup.array().of(
          yup.object().shape({
            item_name: yup.string().required("Nama item harus diisi"),
            quantity: yup.string().isNumber().required("Jumlah barang harus diisi"),
            cost: yup.string().isNumber().required("Harga barang harus diisi"),
            subtotal: yup.string().isNumber().required("Subtotal harus diisi"),
            detail_activity_name: yup.object().required("Nama kegiatan harus diisi"),
            budget_account_name: yup.object().required("Nama akun anggaran harus diisi"),
          })
        ),
      }),
    }),
    items: yup.mixed().when("activeList", {
      is: 3,
      then: () => yup.object().shape({
        total_estimated_cost: yup.string().required("Total estimasi biaya harus diisi"),
        required_items: yup.array().of(
          yup.object().shape({
            item_name: yup.string().required("Nama item harus diisi"),
            quantity: yup.string().isNumber().required("Jumlah barang harus diisi"),
            cost: yup.string().isNumber().required("Harga barang harus diisi"),
            subtotal: yup.string().isNumber().required("Subtotal harus diisi"),
            detail_activity_name: yup.object().required("Nama kegiatan harus diisi"),
            budget_account_name: yup.object().required("Nama akun anggaran harus diisi"),
          })
        ),
      }),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      resource: {
        total_person: null,
        total_estimated_cost: null,
        required_items: [
          {
            item_name: null,
            quantity: null,
            cost: null,
            subtotal: null,
            detail_activity_name: null,
            budget_account_name: null,
          },
        ],
      },
      participant: {
        total_person: null,
        total_estimated_cost: null,
        required_items: [
          {
            item_name: null,
            quantity: null,
            cost: null,
            subtotal: null,
            detail_activity_name: null,
            budget_account_name: null,
          },
        ],
      },
      items: {
        total_estimated_cost: null,
        required_items: [
          {
            item_name: null,
            quantity: null,
            cost: null,
            subtotal: null,
            detail_activity_name: null,
            budget_account_name: null,
          },
        ],
      },
    },
    mode: "onBlur",
  });
  const { lists, recapIsLoading } = useRecapMutations(TORID, onFecthed);

  const workplan = useQuery({
    queryKey: ["detail-timeWorkPlan", lists?.tor?.work_plan_id],
    queryFn: () => getSpecificTimeWorkPlanDetail(lists?.tor?.work_plan_id),
    onSuccess: (data) => {
      const activities = data?.data?.data?.awp_detail_activities?.map((item) => ({
        ...item,
        value: item.awp_detail_activity_id,
        label: item.awp_detail_activity_name,
      }));

      setDetailActivities(activities);
    },
    refetchOnWindowFocus: false,
    enabled: !!lists?.tor?.work_plan_id,
  });

  const {
    submitResources,
    submitParticipants,
    submitItems,
    refetchBudgetAccountData,
    dataBudgetAccount,
  } = useRabMutations();

  const handleSubmitForm = async (params, isBack) => {
    try {
      const remapItems = (items) => {
        return items.map((item) => {
          return {
            ...item,
            detail_activity_id: item.detail_activity_name?.value,
            detail_activity_name: item.detail_activity_name?.label,
            budget_account_id: item.budget_account_name?.value,
            budget_account_name: item.budget_account_name?.label,
          };
        });
      };

      const items = {
        1: remapItems(params?.resource?.required_items ?? []),
        2: remapItems(params?.participant?.required_items ?? []),
        3: remapItems(params?.items?.required_items ?? []),
      }

      const formObjKey = {
        1: "resource",
        2: "participant",
        3: "items",
      }

      const form = params[formObjKey[activeList]];

      let temp = {
        ...form,
        id: TORID,
        user_id: userSelector?.UserId,
        total_person:
          activeList === 1
            ? params?.resource?.total_person
            : activeList === 2
            ? params?.participant?.total_person
            : null,
        required_items: items[activeList],
      };

      switch (activeList) {
        case 1:
          submitResources.mutate(temp, {
            onSuccess: () => {
              if (!isBack) {
                setActiveList(activeList + 1);
                setTotalFunding(0);
                sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
              }
            },
          });
          break;
        case 2:
          submitParticipants.mutate(temp, {
            onSuccess: () => {
              if (!isBack) {
                setActiveList(activeList + 1);
                setTotalFunding(0);
                sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
              }
            },
          });
          break;
        case 3:
          submitItems.mutate(temp, {
            onSuccess: () => {
              if (!isBack) {
                sessionStorage.removeItem("step_rab_tor");
                sessionStorage.setItem("step", 3);
                nextStep();
                setSavedStep(3);
              }
            },
          });
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    methods.setValue("activeList", activeList);
  }, [activeList]);

  useEffect(() => {
    refetchBudgetAccountData();
  }, []);

  function onFecthed(data) {
    const res = data?.data?.data;

    methods.reset({
      tor: res.tor,
      activeList: activeList,
      resource: {
        ...res?.resource,
        required_items: res?.resource?.required_items?.map((item) => ({
          ...item,
          detail_activity_name: item.detail_activity_id ? {
            value: item.detail_activity_id,
            label: item.detail_activity_name,
          } : null,
          budget_account_name: item.budget_account_id ? {
            value: item.budget_account_id,
            label: item.budget_account_name,
          } : null,
        })) ?? [{}],
      },
      participant: {
        ...res?.participant,
        required_items: res?.participant?.required_items?.map((item) => ({
          ...item,
          detail_activity_name: item?.detail_activity_name ? {
            value: item.detail_activity_id,
            label: item.detail_activity_name,
          } : null,
          budget_account_name: item?.budget_account_name ? {
            value: item.budget_account_id,
            label: item.budget_account_name,
          } : null,
        })) ?? [{}],
      },
      items: {
        ...res?.item,
        required_items: res?.item?.required_items?.map((item) => ({
          ...item,
          detail_activity_name: item?.detail_activity_name ? {
            value: item.detail_activity_id,
            label: item.detail_activity_name,
          } : null,
          budget_account_name: item?.budget_account_name ? {
            value: item.budget_account_id,
            label: item.budget_account_name,
          } : null,
        })) ?? [{}],
      },
    });
  }

  function onSubmit(data) {
    if (!isEdit) {
      switch (activeList) {
        case 1:
          setActiveList(activeList + 1);
          sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
          break;
        case 2:
          setActiveList(activeList + 1);
          sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
          break;
        case 3:
          sessionStorage.removeItem("step_rab_tor");
          sessionStorage.setItem("step", 3);
          nextStep();
          setSavedStep(3);
          break;
        default:
          break;
      }
    } else {
      // methods.register("total_estimated_cost");
      // methods.setValue("total_estimated_cost", totalFunding);
      let values = methods.getValues();
      handleSubmitForm(values, false);
    }
  }

  function getEstimationCost() {
    if (activeList === 1) {
      return methods.watch("resource.total_estimated_cost");
    }

    if (activeList === 2) {
      return methods.watch("participant.total_estimated_cost");
    }

    return methods.watch("items.total_estimated_cost");
  }

  if (recapIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-6">
        <div className="flex border-b border-gray-400">
          {subList.map((list) => (
            <div
              key={list.id}
              className={`relative p-3 text-sm font-semibold ${
                activeList === list.id ? "text-[#01A24A] bg-[#EEFFF4]" : ""
              }`}
            >
              {list.label}
              {activeList === list.id && (
                <span className="w-full absolute bottom-0 left-0 inset-x h-0.5 bg-[#01A24A]"></span>
              )}
            </div>
          ))}
        </div>
        <Card>
          <CardHeader>
            <span className="font-semibold text-sm text-[#E34F13] bg-[#FFF4ED] rounded-full px-2 py-1">
              Estimasi kebutuhan biaya : Rp. {getEstimationCost()}
            </span>
          </CardHeader>
          <CardBody>
            {activeList === 1 && (
              <ResourcePerson
                setTotalFunding={setTotalFunding}
                methods={methods}
                disabled={!isEdit}
                TORID={TORID}
                datas={lists}
                activityDetails={detailActivities}
                budgetAccountOptions={dataBudgetAccount}
              />
            )}
            {activeList === 2 && (
              <Participant
                setTotalFunding={setTotalFunding}
                methods={methods}
                disabled={!isEdit}
                TORID={TORID}
                datas={lists}
                activityDetails={detailActivities}
                budgetAccountOptions={dataBudgetAccount}
              />
            )}
            {activeList === 3 && (
              <Items
                setTotalFunding={setTotalFunding}
                methods={methods}
                disabled={!isEdit}
                TORID={TORID}
                datas={lists}
                activityDetails={detailActivities}
                budgetAccountOptions={dataBudgetAccount}
              />
            )}
          </CardBody>
        </Card>
      </div>

      <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
        <div>
          <Button
            type="button"
            className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
            onClick={() => {
              if (activeList !== 1) {
                setActiveList(activeList - 1);
              } else {
                sessionStorage.setItem("step", 1);
                setSavedStep(1);
              }
              sessionStorage.removeItem("step_rab_tor");
            }}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>

        <div className="items-center justify-between block gap-2 md:flex">
          {isEdit && (
            <Button
              type="button"
              className="text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                let values = methods.getValues();
                handleSubmitForm(values, true);
                navigate("/tor");
              }}
            >
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            disabled={
              submitResources.isLoading ||
              submitParticipants.isLoading ||
              submitItems.isLoading
            }
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {submitResources.isLoading ||
            submitParticipants.isLoading ||
            submitItems.isLoading
              ? "Tunggu Sebentar"
              : "Selanjutnya"}
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>

      {/* MODAL */}
      {showModal.personModal && (
        <AddResourcePersonModal
          close={() =>
            setShowModal({ ...showModal, personModal: !showModal.personModal })
          }
        />
      )}
      {/* END MODAL */}
    </FormProvider>
  );
};

export default RabForm;
