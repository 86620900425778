import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardForm,
  InputForm,
  Select as AtomSelect,
  InputFormUpload,
} from 'components';
import FileDetail from 'components/molecules/FileDetail';
import { getAwpDetail } from 'services/danaProgram/awsService';
import { fileUpload } from 'services/danaProgram/fileService';

import useProgressReport from '../hooks/useHooks';

const Child = ({ methods, listAWP, documents }) => {
  // hooks
  const navigate = useNavigate();
  const {
    formState: { isValid },
  } = useFormContext();

  // methods
  function sumContributions(data) {
    let total = 0;

    data.forEach((item) => {
      const activities = item.activity || [];

      activities.forEach((activity) => {
        total += parseInt(activity.contribution);
      });
    });

    return total / data.length;
  }

  const onSubmitVerif = async (type) => {
    try {
      let val = methods.getValues();
      let params = {
        id: sessionStorage.getItem('ProgressReportsId') || null,
        total_contribution: sumContributions(val?.output),
        project_id: val?.project_name?.value,
        project_name: val?.project_name?.label,
        output: val?.output.map((data) => ({
          indicator_list: data?.activity,
          name: data?.name,
        })),
        document: val.document,
      };

      let supporting_doc = [];

      for (let file of val.document) {
        if (file instanceof File) {
          const fileFormData = new FormData();
          fileFormData.append('file', file);

          const resFile = await fileUpload(fileFormData);
          const resDataFile = await resFile.data.data;

          const payloadPerFile = {
            fileId: resDataFile.id,
            fileName: resDataFile.name,
            fileSize: resDataFile.size,
            mimeType: resDataFile.mimeType,
            path: resDataFile.url,
          };

          supporting_doc.push(payloadPerFile);
        } else {
          supporting_doc.push(file);
        }
      }

      params.document = supporting_doc;

      submitProgressReport.mutate(params, {
        onSuccess: () => {
          sessionStorage.removeItem('ProgressReportsId');
          enqueueSnackbar('Data berhasil disimpan!', {
            variant: 'success',
            preventDuplicate: false,
          });
          navigate('/progress-report/');
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // requests
  const { submitProgressReport } = useProgressReport({ test: '' });

  const { data: awpDetail } = useQuery({
    enabled: !!methods.getValues('project_name')?.value,
    queryKey: ['get-awp-detail', methods.watch('project_name')],
    queryFn: async () => {
      const res = await getAwpDetail(
        methods.getValues('project_name')?.value,
        {}
      );

      return res.data.data;
    },
  });

  // cycles
  useEffect(() => {
    if (awpDetail) {
      for (let key in awpDetail) {
        if (key === 'output') {
          methods.setValue(key, awpDetail[key]);
        }
      }
    }
  }, [awpDetail]);

  return (
    <div className="">
      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader>
            <div>Update Laporan Kemajuan</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="flex-1">
                <AtomSelect
                  controllerName={`project_name`}
                  label="Nama Project"
                  className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                  options={listAWP ? listAWP : []}
                  // disable={!isAdd}
                />
              </div>
              <div className="flex-1">
                <p className="text-lg font-semibold mb-2">Capaian Output</p>
                <div className="flex flex-col gap-6">
                  {awpDetail &&
                  awpDetail.output &&
                  awpDetail.output.length > 0 ? (
                    awpDetail.output.map((data, index) => (
                      <CardForm
                        key={index}
                        label={`Output ${index + 1}`}
                        buttonCollapse={true}
                      >
                        <div className="flex flex-col gap-6">
                          {awpDetail.output[index].activity.map((dat, i) => (
                            <div className="flex gap-4 flex-row">
                              <InputForm
                                controllerName={`output.${index}.activity.${i}.name`}
                                className="py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]"
                                label={'Indikator A'}
                                disabled={true}
                              />{' '}
                              <InputForm
                                controllerName={`output.${index}.activity.${i}.contribution`}
                                className="py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]"
                                label={'Update Kontribusi Capaian'}
                                suffix={'%'}
                              />{' '}
                            </div>
                          ))}
                        </div>
                      </CardForm>
                    ))
                  ) : (
                    <CardForm label="Output 1" buttonCollapse={true}>
                      <div className="flex flex-col gap-6">
                        <div className="flex gap-4 flex-row">
                          <InputForm
                            controllerName={`name`}
                            className="py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]"
                            label={'Indikator A'}
                            disabled={true}
                          />{' '}
                          <InputForm
                            controllerName={`contribution`}
                            className="py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]"
                            label={'Update Kontribusi Capaian'}
                            suffix={'%'}
                          />{' '}
                        </div>
                      </div>
                    </CardForm>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Dokumen Pendukung</CardHeader>
          <CardBody>
            <div className="py-5 space-y-5">
              <div className="space-y-2">
                <div className="flex text-[#1D2939] font-semibold text-sm">
                  Upload Dokumen Bukti Penggunaan Biaya
                  <span className="text-error-500 text-sm">*</span>
                </div>
                <InputFormUpload
                  controllerName={'document'}
                  maxSize={10240}
                  label=""
                  isMulti
                  hideSectionUpload={false}
                  listFiles={[]}
                />
                {documents &&
                  documents.length > 0 &&
                  documents.map((file, key) => (
                    <FileDetail
                      key={'progress-report-data-document-' + key}
                      file={file}
                      hideDelete
                    />
                  ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate('/progress-report/')}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <Button
          disabled={!isValid}
          type="button"
          className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          onClick={() => onSubmitVerif()}
        >
          Submit
          <RiArrowRightLine className="text-white" />
        </Button>
      </div>
    </div>
  );
};

export default Child;
