import React, { Fragment, useEffect } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
} from 'components';
import FileDetail from 'components/molecules/FileDetail';
import useApprovalPembayaranLemtara from 'pages/ApprovalPembayaranLemtara/hooks/useApprovalPembayaranLemtara';
import Approval2LevelActions, {
  Progress as Approval2LevelProgress,
} from 'components/organisms/Approval2Level';

const ApprovalPembayaranlemtaraDetail = ({ isReview = false }) => {
  const links = [
    {
      label: 'Daftar Permohonan Pembayaran lemtara',
      path: '/approval-pembayaran-lemtara',
    },
    {
      label: isReview ? 'Review' : 'Detail',
    },
  ];

  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm();
  const { fields } = useFieldArray({
    name: 'requirement_item',
    control: methods.control,
  });

  const docs = methods.getValues('attachment');

  const { detailApprovalPembayaranLemtara, refetchDetail } =
    useApprovalPembayaranLemtara({ params });

  useEffect(() => {
    if (params?.id) {
      refetchDetail();
    }
  }, []);

  useEffect(() => {
    if (detailApprovalPembayaranLemtara) {
      methods.setValue(
        'project_name',
        detailApprovalPembayaranLemtara.project_name
      );
      methods.setValue(
        'output_name',
        detailApprovalPembayaranLemtara.output_name
      );
      methods.setValue(
        'main_activity_name',
        detailApprovalPembayaranLemtara.main_activity_name
      );
      methods.setValue(
        'background',
        detailApprovalPembayaranLemtara.background
      );
      methods.setValue('purpose', detailApprovalPembayaranLemtara.purpose);
      methods.setValue(
        'activity_outcome',
        detailApprovalPembayaranLemtara.activity_outcome
      );
      methods.setValue(
        'member_description',
        detailApprovalPembayaranLemtara.member_description
      );
      methods.setValue(
        'member_of_male',
        detailApprovalPembayaranLemtara.member_of_male
      );
      methods.setValue(
        'member_of_female',
        detailApprovalPembayaranLemtara.member_of_female
      );

      const requirementItems =
        detailApprovalPembayaranLemtara.requirement_item.map((item) => ({
          ...item,
          cost: `Rp ${item.subcost.toLocaleString()}`,
          subtotal: `Rp ${item.subtotal.toLocaleString()}`,
        }));

      methods.setValue('requirement_item', requirementItems);
      methods.setValue('attachment', detailApprovalPembayaranLemtara.document);
    }
  }, [detailApprovalPembayaranLemtara]);

  return (
    <Fragment>
      <FormProvider {...methods}>
        <div className="flex flex-col gap-8">
          <Breadcrumbs items={links} />
          <Card>
            <CardHeader>INFORMASI PROYEK</CardHeader>
            <CardBody>
              <div className="space-y-[9px]">
                <div>
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Nama Project
                  </label>
                  <InputForm
                    controllerName={'project_name'}
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    disabled
                  />
                </div>
                <div>
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Output Project
                  </label>
                  <InputForm
                    controllerName={'output_name'}
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    disabled
                  />
                </div>
                <div>
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Kegiatan Utama yang diusulkan (berdaarkan AWP)
                  </label>
                  <InputForm
                    controllerName={'main_activity_name'}
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    disabled
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>TOR KEGIATAN</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-6">
                <div>
                  <InputForm
                    controllerName={'background'}
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={'Latar Belakang'}
                    disabled
                    textArea
                  />
                </div>
                <div>
                  <InputForm
                    controllerName={'purpose'}
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={'Maksud Dan Tujuan'}
                    disabled
                    textArea
                  />
                </div>
                <div>
                  <label className="text-md text-green-500 font-semibold">
                    Target Kegiatan
                  </label>
                </div>
                <div className="flex gap-4">
                  <div className="w-full">
                    <InputForm
                      controllerName="activity_outcome"
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={'Target Hasil'}
                      disabled
                    />
                  </div>
                </div>
                <div>
                  <label className="text-md text-green-500 font-semibold">
                    Peserta
                  </label>
                </div>
                <div className="flex gap-4">
                  <div className="w-[50%]">
                    <InputForm
                      controllerName="member_description"
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={'Peserta / Kelompok Peserta'}
                      disabled
                    />
                  </div>
                  <div className="w-[25%]">
                    <InputForm
                      controllerName="member_of_male"
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={'Jumlah Laki Laki'}
                      disabled
                    />
                  </div>
                  <div className="w-[25%]">
                    <InputForm
                      controllerName="member_of_female"
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={'Jumlah Perempuan'}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>DOKUMEN PENDUKUNG</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-6">
                {docs &&
                  docs.map((doc, index) => (
                    <FileDetail
                      key={index}
                      file={doc}
                      onRemove={() => {}}
                      hideDelete
                    />
                  ))}
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>RENCANA ANGGARAN BIAYA</CardHeader>
            <CardBody>
              {fields.map((field, index) => (
                <div key={field.id} className="flex flex-col gap-6">
                  <div>
                    <InputForm
                      controllerName={`requirement_item.${index}.name`}
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={'Item Kebutuhan'}
                      disabled
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="w-[25%]">
                      <InputForm
                        controllerName={`requirement_item.${index}.qty`}
                        className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={'Qty'}
                        disabled
                      />
                    </div>
                    <div className="w-[25%]">
                      <InputForm
                        controllerName={`requirement_item.${index}.volume`}
                        className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={'Volume / Frekuensi'}
                        disabled
                      />
                    </div>
                    <div className="w-[25%]">
                      <InputForm
                        controllerName={`requirement_item.${index}.cost`}
                        className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={'Satuan Biaya Rp'}
                        disabled
                      />
                    </div>
                    <div className="w-[25%]">
                      <InputForm
                        controllerName={`requirement_item.${index}.subtotal`}
                        className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={'Subtotal Rp'}
                        disabled
                      />
                    </div>
                  </div>
                  <div>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Detail Kegiatan
                    </label>
                    <InputForm
                      controllerName={`requirement_item.${index}.activity_name`}
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Mata Anggaran
                    </label>
                    <InputForm
                      controllerName={`requirement_item.${index}.budget_name`}
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      disabled
                    />
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>

          <Approval2LevelProgress
            module="pembayaran-resources"
            credentials={detailApprovalPembayaranLemtara?.credentials}
            rejectReason={detailApprovalPembayaranLemtara?.reason}
          />

          <div
            className={`block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10`}
          >
            <div>
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
                onClick={() => navigate('/approval-pembayaran-lemtara')}
              >
                <RiArrowLeftLine />
                Kembali
              </Button>
            </div>

            <div className="flex gap-4 flex-row">
              {isReview && (
                <Approval2LevelActions
                  module="pembayaran-lemtara"
                  id={params.id}
                  status={detailApprovalPembayaranLemtara?.status}
                />
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    </Fragment>
  );
};

export default ApprovalPembayaranlemtaraDetail;
