import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  DateInput,
} from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { RiArrowLeftLine, RiFile2Line } from 'react-icons/ri';
import config from 'app/config';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUrlFile } from 'services/danaProgram/fileService';
import axiosInstance from 'app/interceptors';
import moment from 'moment';

const DetailConsepNote = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState();

  let { id } = useParams();

  const links = [
    {
      label: 'Daftar Permohonan Konsep Note',
    },
    {
      label: 'Detail',
    },
  ];

  const fetchDetailConsep = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/notes/detail/${id}`
    );
    setData(res.data.data);
  };

  const statusBadge = (value) => {
    switch (value) {
      case 'Disetujui':
        return (
          <div className="bg-[#ECFDF3] px-3 rounded-[25px] flex gap-[1px] w-fit mx-auto items-center">
            <div className="text-[#027A48] font-medium text-[12px]">
              Disetujui
            </div>
          </div>
        );
        break;
      case 'Ditolak':
        return (
          <div className="bg-[#FEF3F2] px-1 rounded-[25px] flex gap-[1px] items-center w-fit mx-auto">
            <div className="text-[#B42318] font-medium text-[12px]">
              Ditolak
            </div>
          </div>
        );
        break;
      default:
        return <></>;
        break;
    }
  };
  useEffect(() => {
    fetchDetailConsep();
  }, []);

  return (
    <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[81vw]">
      <Breadcrumbs items={links} />
      <div className="flex flex-col gap-4 mt-5">
        <div className="flex flex-col gap-4 mt-5">
          <Card>
            <CardHeader>
              <span className="text-xl">Detail</span>
            </CardHeader>
            <CardBody>
              <div className="flex flex-row">
                <div className="flex flex-col col-span-2 w-1/2">
                  <span className="font-semibold">Proyek Yang diajukan</span>
                  <span>{data?.project_name}</span>
                </div>
                <div className="flex flex-col col-span-2 w-1/2">
                  {data?.status === 'Menunggu Persetujuan' ? (
                    <div> </div>
                  ) : (
                    <>
                      <span className="font-semibold">Status</span>
                      <span>{data?.status}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col mt-6">
                  <span className="font-semibold">Outcome</span>
                  <ul className="list-disc ml-4">
                    {data?.outcome.map((outcome, index) => (
                      <li key={index}>{outcome.label}</li>
                    ))}
                  </ul>
                </div>
                <div className="flex flex-col mt-6">
                  <span className="font-semibold">Output</span>
                  <ul className="list-disc ml-4">
                    {data?.output.map((output, index) => (
                      <li key={index}>{output.label}</li>
                    ))}
                  </ul>
                </div>
                <div className="flex flex-col mt-6">
                  <span className="font-semibold">Judul Proposal</span>
                  <span>{data?.proposal_title}</span>
                </div>
                <div className="flex flex-col mt-6">
                  <span className="font-semibold">Deskripsi Proposal</span>
                  <span>{data?.description_proposal}</span>
                </div>
                <div className="flex flex-col mt-6">
                  <span className="font-semibold">Lokasi Kegiatan</span>
                  <ul className="list-disc ml-4">
                    {data?.location.map((loc, index) => (
                      <li key={index}>
                        {`${loc?.village?.label}, ${loc?.district?.label}, ${loc?.cities?.label}, ${loc?.province?.label}`}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-6">
                <div className="flex flex-col">
                  <span className="font-semibold">Tanggal Pengajuan</span>
                  <span>
                    {moment(data?.date_of_filling).format('DD-MM-YYYY')}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">
                    Lokasi kegiatan berada diwilayah konversi
                  </span>
                  {data?.is_conversion_area === true ? (
                    <>
                      <span>Ya</span>
                      <span>{data?.location_conversion_area}</span>
                    </>
                  ) : (
                    <span>Tidak</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">
                    Kegiatan ini melibatkan masyarakat adat
                  </span>
                  {data?.is_involve_indigenous_comunities === true ? (
                    <>
                      <span>Ya</span>
                      <span>{data?.comunities_name}</span>
                    </>
                  ) : (
                    <span>Tidak</span>
                  )}
                  <span></span>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-6">
                <div className="flex flex-col">
                  <span className="font-semibold">
                    Jumlah beneficiaries perempuan
                  </span>
                  <span>{data?.total_beneficiaries.female}</span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">
                    Jumlah beneficiaries laki -laki
                  </span>
                  <span>{data?.total_beneficiaries.male}</span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">Total Beneficiaries</span>
                  <span>{data?.total_beneficiaries.total}</span>
                </div>
              </div>
              <div className="mt-6">
                {data?.file_beneficiaries_document?.map((e) => {
                  return (
                    <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                      <div className="flex gap-4">
                        <div>
                          <div className="bg-[#EEFFF4] rounded-full p-4">
                            <RiFile2Line className="text-primary-600" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="font-medium">{e.fileName}</span>
                          <span>{`${Number(e.fileSize).toFixed(2)} MB`}</span>
                          <span
                            className="font-semibold text-primary-600 cursor-pointer"
                            onClick={async (event) => {
                              if (data?.status !== 'Ditolak') {
                                const res = await getUrlFile({
                                  identifier: 'id',
                                  value: e.fileId,
                                });
                                window.open(res?.data?.data?.url, '_blank');
                              } else {
                                event.preventDefault();
                              }
                            }}
                          >
                            Lihat Dokumen
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }) ?? null}
              </div>
              <div className="flex flex-col mt-6">
                <span className="font-semibold">
                  Safeguard yang akan diterapkan
                </span>
                {data?.safeGuards?.map((el) => {
                  return (
                    <ul className="ml-5">
                      <li className="list-disc">{el?.value}</li>
                    </ul>
                  );
                }) ?? null}
              </div>
              <div className="mt-6">
                {data?.rab_documents?.map((e) => {
                  return (
                    <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                      <div className="flex gap-4">
                        <div>
                          <div className="bg-[#EEFFF4] rounded-full p-4">
                            <RiFile2Line className="text-primary-600" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="font-medium">{e.fileName}</span>
                          <span>{`${Number(e.fileSize).toFixed(2)} MB`}</span>
                          <span
                            className="font-semibold text-primary-600 cursor-pointer"
                            onClick={async (event) => {
                              if (data?.status !== 'Ditolak') {
                                const res = await getUrlFile({
                                  identifier: 'id',
                                  value: e.fileId,
                                });
                                window.open(res?.data?.data?.url, '_blank');
                              } else {
                                event.preventDefault();
                              }
                            }}
                          >
                            Lihat Dokumen
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }) ?? null}
              </div>
            </CardBody>
          </Card>
        </div>
        {data?.status === 'Ditolak' ? (
          <Card>
            <CardHeader>
              <span>Alasan Penolakan</span>
            </CardHeader>
            <CardBody>
              <span>{data?.reason_rejected}</span>
            </CardBody>
          </Card>
        ) : (
          <div></div>
        )}
      </div>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate('/concept-notes')}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailConsepNote;
