import React, { useCallback, useEffect, useState } from 'react';
import { getAwpDetail, getList } from 'services/danaProgram/awsService';
import { useLocation } from 'react-router-dom';
import Child from './form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Breadcrumbs } from 'components';
import { useQuery } from '@tanstack/react-query';
import { getSpecificTimeWorkPlan } from 'services/danaProgram/specificTimeWorkPlanService';
import * as yup from 'yup';
import useProjectManagementTeam from '../hooks/useHooks';
import useProgressReport from '../hooks/useHooks';

const ProgressReportForm = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [edit, setEdit] = useState(true);
  const idInvestment = JSON.parse(localStorage.getItem('id_investment'));

  const [AWP, setAWP] = useState([]);
  const [listAllEmployee, setListAllEmployee] = useState();
  const { employeeList, refetchEmployeeList } = useProjectManagementTeam({
    test: 'test',
  });

  const id = sessionStorage.getItem('ProgressReportsId');
  const params = { id: id };
  const { ProgressReport, refetchProgressReport } = useProgressReport({
    params,
  });

  const validationSchema = yup.object().shape({
    project_name: yup.mixed().required('Projek Harus Dipilih'),
    output: yup.array().of(
      yup.object().shape({
        activity: yup.array().of(
          yup.object().shape({
            contribution: yup
              .string()
              .required('Kontribusi Capaian wajib diisi')
              .matches(/^[0-9]+$/, 'Kontribusi Capaian wajib menggunakan angka')
              .test(
                'is-valid-range',
                'Kontribusi Capaian wajib diisi dengan range 0 - 100',
                (value) => value >= 0 && value <= 100
              ),
          })
        ),
      })
    ),

    document: yup
      .array('Upload Dokumen Bukti Penggunaan Biaya Wajib Diisi')
      .of(
        yup
          .mixed()
          .test(
            'fileType',
            'Format file wajib diisi dengan format .pdf/.jpeg/.png',
            (value) => {
              if (!value) return true; // If value is empty, skip validation

              const supportedTypes = [
                'application/pdf',
                'image/jpeg',
                'image/png',
              ];

              for (let i = 0; i < value.length; i++) {
                if (!supportedTypes.includes(value[i].type)) {
                  return false; // Return false if any file does not match supported types
                }
              }

              return true; // All files match supported types
            }
          )
      )
      .required('Dokumen Bukti Penggunaan Biaya Wajib Diisi')
      .typeError('Dokumen Bukti Penggunaan Biaya Wajib Diisi')
      .test(
        'notEmpty',
        'Dokumen Bukti Penggunaan Biaya Wajib Diisi',
        (value) => {
          return !!value;
        }
      ),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (id !== null) {
      refetchProgressReport();
    }
  }, [id]);

  useEffect(() => {
    if (id !== null) {
      for (let key in ProgressReport) {
        if (key === 'project_name') {
          methods.setValue(key, {
            label: ProgressReport[key],
            value: ProgressReport?.project_id,
          });
        } else if (key === 'output') {
          ProgressReport[key].length > 0 &&
            ProgressReport[key].map((data, index) => {
              data?.indicator_list?.length > 0 &&
                data?.indicator_list.map((d, i) => {
                  methods.setValue(
                    `${key}.${index}.activity.${i}.contribution`,
                    d?.contribution
                  );
                });
            });
        } else {
          methods.setValue(key, ProgressReport[key]);
        }
      }
    }
  }, [ProgressReport]);

  useEffect(() => {
    refetchEmployeeList();
  }, []);

  const { data: awpList } = useQuery({
    queryKey: ['get-awp-list'],
    queryFn: async () => {
      const res = await getList({ status: 'dikirim' });

      return res.data.data;
    },
  });

  useEffect(() => {
    if (awpList) {
      let mapped = awpList?.data?.map((item) => ({
        label: item?.proposal_number,
        value: item?._id,
        ...item,
      }));
      setAWP((prevState) => [...prevState, ...mapped]);
    }
  }, [awpList]);

  function mapOption(data) {
    let temp = data?.map((obj) => ({
      value: obj.name,
      label: obj.name,
      ...obj,
    }));
    setListAllEmployee(temp);
  }

  useEffect(() => {
    mapOption(employeeList?.data?.data);
  }, [employeeList]);

  useEffect(() => {
    if (currentPath.includes('detail')) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, []);

  const links = [
    {
      label: 'Daftar Laporan Kemajuan',
      path: `/progress-report`,
    },
    {
      label: 'Tambah/Edit Laporan kemajuan',
    },
  ];

  return (
    <div>
      {/* start Breadcrumb */}
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <FormProvider {...methods}>
        <Child
          permission={edit}
          title={'Assign Tim Pengelola Proyek'}
          methods={methods}
          listAWP={AWP}
        />
      </FormProvider>
    </div>
  );
};

export default ProgressReportForm;
