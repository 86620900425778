import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import ApexCharts from "apexcharts";

import {
  TitleText,
  Breadcrumbs,
  Card,
  CardHeader,
  CardBody,
  CardForm,
  Select,
  CustomSelect,
  AtomDatePicker,
} from "components";
import { convertMoney } from "helpers";
import {
  BeneficiariesOpt,
  DanaOpt,
  FullProposalOpt,
  IndicatorChartOpt,
  KonsepNoteOpt,
  OutcomeOpt,
  OutputOpt,
  PenyaluranLineOpt,
  PertumbuhanLineOpt,
  ProgressChartOpt,
  ProyekDonutOpt,
  ProyekLineOpt,
  TeamProyekOpt,
} from "./dummy";
import { IoDocument } from "react-icons/io5";
import { MdGroups, MdOutlineMan2, MdOutlineWoman2 } from "react-icons/md";
import { GiMoneyStack, GiReceiveMoney, GiPayMoney } from "react-icons/gi";
import AtomSelect from "components/atoms/Select";
import { GrDocumentPerformance, GrDocumentStore } from "react-icons/gr";
import { RiExchangeFundsLine } from "react-icons/ri";
import ReactDatePicker from "react-datepicker";
import useDashboard from "./hooks/useDashboard";
import moment from "moment";
import { isEmpty } from "lodash";
import DonutChart from "./charts/DonutChart";
import BarChart from "./charts/BarChart";

const Verification = () => {
  const chartRef = useRef(null);
  const ProyekDonutChartRef = useRef(null);

  const Total1DonutChartRef = useRef(null);
  const Total3DonutChartRef = useRef(null);

  const ProyekLineChartRef = useRef(null);

  // const TahunanChartRef = useRef(null);
  // const BulananChartRef = useRef(null);
  const PertumbuhanLineRef = useRef(null);
  const IndicatorChartRef = useRef(null);

  // const ProgressChartRef = useRef(null);
  // const DanaRef = useRef(null);

  // const OutcomeRef = useRef(null);
  // const OutputRef = useRef(null);

  // const SubOutputRef = useRef(null);
  // const OutputProyekRef = useRef(null);

  const currentDate = new Date(),
    y = currentDate.getFullYear(),
    m = currentDate.getMonth();
  const firstDay = new Date(y, m, 1);

  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(currentDate);

  const [optionListPendonor, setOptionListPendonor] = useState([
    { label: null, value: null, commitment: {}, realization: {} },
  ]);
  const [filterPendonor, setFilterPendonor] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const links = [
    {
      label: "Dashboard Dana Program",
    },
  ];

  const params = {
    start_date: startDate ? moment(startDate).format("DD/MM/YYYY") : null,
    end_date: endDate ? moment(endDate).format("DD/MM/YYYY") : null,
  };

  const {
    totalCommitmentData,
    refetchTotalCommitmentData,
    totalReportedFundData,
    refetchTotalReportedFundData,
    totalDistributionMechanismData,
    refetchTotalDistributionMechanismData,
    detailDistributionData,
    refetchDetailDistributionData,
    totalDonorData,
    refetchTotalDonorData,
    cfpConceptNotesData,
    refetchCfpConceptNotesData,
    userBeneficiariesData,
    refetchUserBeneficiariesData,
    projectTeamsData,
    refetchProjectTeamsData,
  } = useDashboard({
    params,
  });

  useEffect(() => {
    refetchTotalCommitmentData();
    refetchTotalReportedFundData();
    refetchTotalDistributionMechanismData();
    refetchDetailDistributionData();
    refetchTotalDonorData();
    refetchCfpConceptNotesData();
    refetchUserBeneficiariesData();
    refetchProjectTeamsData();
  }, []);

  useEffect(() => {
    if (!isEmpty(detailDistributionData?.commitment)) {
      const optionList = [];
      let commitment = {};
      let realization = {};
      detailDistributionData?.commitment.forEach((item) => {
        commitment = {};
        realization = {};
        item?.projects.forEach((proj) => {
          commitment = { ...commitment, [proj._id]: proj.sum };
        });

        detailDistributionData.realization
          .filter((e) => e.name === item.name)
          .forEach((item2) => {
            item2?.projects.forEach((proj) => {
              realization = { ...realization, [proj._id]: proj.sum };
            });
          });
        optionList.push({
          label: item.name,
          value: item.name,
          commitment: commitment,
          realization: realization,
        });
      });
      setOptionListPendonor(optionList);
    }
  }, [detailDistributionData]);

  useEffect(() => {
    refetchTotalCommitmentData();
    refetchTotalReportedFundData();
  }, [startDate, endDate]);

  useEffect(() => {
    const options = {
      chart: {
        type: "line",
        height: "323.5px",
      },

      colors: ["#00BD52", "#F04438", "#2970FF"],

      series: [
        {
          name: "Dana Hibah yg Masuk",
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
        },
        {
          name: "Dana yang Udah Disalurkan",
          data: [60, 70, 91, 30, 40, 35, 50, 49, 150],
        },
        {
          name: "Dana yang Sudah Dilaporkan",
          data: [21, 34, 80, 45, 47, 69, 72, 81, 105],
        },
      ],
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
      },
      markers: {
        size: 6,
      },
    };
    const DonutOptions = {
      chart: {
        type: "donut",
        height: "290px",
      },
      colors: ["#12B76A", "#F79009", "#F04438", "#2E90FA"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },

      series: [30000000, 36000000, 25000000, 59000000],
      labels: ["Thematic 1", "Thematic 2", "Thematic 3", "Thematic 4"],
      legend: {
        position: "bottom",
        formatter: function (val, opts) {
          return `${val}: Rp. ${convertMoney(
            opts.w.globals.series[opts.seriesIndex]
          )}`;
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    const ProyekLineChart = new ApexCharts(
      ProyekLineChartRef.current,
      ProyekLineOpt
    );
    const donutProyekChart = new ApexCharts(
      ProyekDonutChartRef.current,
      ProyekDonutOpt
    );
    const donutTotalChart = new ApexCharts(
      Total1DonutChartRef.current,
      ProyekDonutOpt
    );

    const donutTotal3Chart = new ApexCharts(
      Total3DonutChartRef.current,
      ProyekDonutOpt
    );

    // const TahunanChart = new ApexCharts(
    //   TahunanChartRef.current,
    //   PenyaluranLineOpt
    // );
    // const BulananChart = new ApexCharts(
    //   BulananChartRef.current,
    //   PenyaluranLineOpt
    // );
    const PertumbuhanChart = new ApexCharts(
      PertumbuhanLineRef.current,
      PertumbuhanLineOpt
    );
    const IndicatorChart = new ApexCharts(
      IndicatorChartRef.current,
      IndicatorChartOpt
    );
    // const ProgressChart = new ApexCharts(
    //   ProgressChartRef.current,
    //   ProgressChartOpt
    // );
    // const DanaChart = new ApexCharts(DanaRef.current, DanaOpt);

    // const OutcomeChart = new ApexCharts(OutcomeRef.current, OutcomeOpt);
    // const OutputChart = new ApexCharts(OutputRef.current, OutputOpt);

    // const SubOutputChart = new ApexCharts(SubOutputRef.current, OutcomeOpt);
    // const OutputProyekChart = new ApexCharts(
    //   OutputProyekRef.current,
    //   OutputOpt
    // );

    // ProgressChart.render();
    // DanaChart.render();

    // chart.render();
    // donutProyekChart.render();
    // ProyekLineChart.render();

    donutTotalChart.render();
    donutTotal3Chart.render();

    // TahunanChart.render();
    // BulananChart.render();

    // PertumbuhanChart.render();
    // IndicatorChart.render();
    // OutcomeChart.render();
    // OutputChart.render();
    // SubOutputChart.render();
    // OutputProyekChart.render();

    return () => {
      // chart.destroy();
      // donutProyekChart.destroy();
      // ProyekLineChart.destroy();
      donutTotalChart.destroy();
      donutTotal3Chart.destroy();

      // TahunanChart.destroy();
      // BulananChart.destroy();
      // PertumbuhanChart.destroy();
      // IndicatorChart.destroy();

      // ProgressChart.destroy();
      // DanaChart.destroy();

      // OutcomeChart.destroy();
      // OutputChart.destroy();

      // SubOutputChart.destroy();
      // OutputProyekChart.destroy();
    };
  }, []);

  // const chart = new ApexCharts(document.querySelector("#chart"), options);

  // useEffect(() => {
  //   if (chart) {
  //     chart.render();
  //   }
  // }, [chart]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />
      <div className="py-1 bg-[#F9FAFB] w-full rounded-lg">
        <span className="text-2xl font-semibold">Dashboard Dana Program</span>
      </div>
      <Card>
        <CardHeader>
          <div>Jumlah proyek Berdasarkan Mekanisme Penyaluran</div>
        </CardHeader>
        <CardBody>
          <div className="grid grid-cols-4 gap-4">
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GrDocumentPerformance className="text-lg text-[#00BD52]" />
                </div>
                <span className="text-sm font-semibold">KL Enabling</span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  {totalDistributionMechanismData?.find(
                    (item) => item._id === "KL Enabling"
                  )?.count ?? "0"}
                </span>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GrDocumentStore className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-sm font-semibold">KL Non-Enabling</span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  {totalDistributionMechanismData?.find(
                    (item) => item._id === "KL Non Enabling"
                  )?.count ?? "0"}
                </span>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GiPayMoney className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-sm font-semibold">
                  Penyaluran Langsung
                </span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  {totalDistributionMechanismData?.find(
                    (item) => item._id === "Penyaluran Langsung"
                  )?.count ?? "0"}
                </span>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GiReceiveMoney className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-sm font-semibold">
                  Penyaluran Tidak Langsung
                </span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  {totalDistributionMechanismData?.find(
                    (item) => item._id === "Penyaluran Tidak Langsung"
                  )?.count ?? "0"}
                </span>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {/*
      <Card>
        <CardHeader>
          <div>Dashboard Dana Program</div>
        </CardHeader>
        <CardBody>
          <div ref={chartRef}></div>
        </CardBody>
      </Card> */}
      <Card>
        <CardHeader>
          <div>Donor</div>
        </CardHeader>
        <CardBody>
          <div className="w-full bg-[#01A24A] mb-4 p-4 rounded-xl text-white">
            <div className="w-full bg-[#4DBE80] p-4 rounded-xl text-white border border-white">
              <p>Total Donor</p>
              <p className="text-xl font-bold">{totalDonorData ?? 0}</p>
            </div>
          </div>
          <div className="flex justify-between px-2 items-center my-4">
            <div className="text-xl font-medium">Filter</div>
            <div className="flex gap-3 space-w-4 w-[calc(100vw/4)]">
              <div className="flex-1 w-1/4">
                <CustomSelect
                  optionsData={[
                    { label: "All", value: null },
                    ...optionListPendonor,
                  ]}
                  className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
                  defaultValue={{ label: "All", value: null }}
                  onChange={(value) => setFilterPendonor(value.value)}
                />
              </div>
            </div>
          </div>
          {optionListPendonor
            .filter(
              (e) => e.value === filterPendonor || filterPendonor === null
            )
            .map((item) => (
              <div className="grid grid-cols-2 gap-4 mb-4">
                <CardForm
                  key={`commitment-${item.value}`}
                  label={`Nilai Komitmen - ${item?.value}`}
                >
                  {item?.commitment && <DonutChart data={item?.commitment} />}
                </CardForm>
                <CardForm
                  key={`realization-${item?.value}`}
                  label={`Nilai Penyaluran - ${item?.value}`}
                >
                  {!isEmpty(item?.realization) ? (
                    <DonutChart data={item?.realization} />
                  ) : (
                    <span class="italic text-slate-400">
                      Data tidak tersedia
                    </span>
                  )}
                </CardForm>
              </div>
            ))}
        </CardBody>
      </Card>
      {/* <Card>
        <CardHeader>
          <div>Proyek</div>
        </CardHeader>
        <CardBody className="grid grid-cols-2 gap-4">
          <CardForm label={`Berdasarkan Thematic Program`}>
            <div ref={ProyekDonutChartRef}></div>
          </CardForm>
          <CardForm label={`Bulanan`}>
            <div ref={ProyekLineChartRef}></div>
          </CardForm>
        </CardBody>
      </Card> */}
      <div className="flex justify-between px-2 items-center">
        <div className="text-xl font-medium">Filter</div>
        <div className="flex gap-3 space-w-4 w-[calc(100vw/4)]">
          {" "}
          {/* <div className="flex-1 w-1/4">
            <CustomSelect
              optionsData={[{ label: "Dana Terra", value: "Dana Terra" }]}4
              placeholder="Status"
              className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 !bg-transparent text-sm outline-none focus:outline-none"
              // onChange={onChangeStatus}
              value={{ label: "Dana Terra", value: "Dana Terra" }}
            />
          </div> */}
          <div className="flex-1 w-1/4 float-right text-end">
            <ReactDatePicker
              className="w-full rounded-md border-gray-200 border-solid text-gray-600 text-sm"
              wrapperClassName="w-[250px] rounded-md border-gray-200 border-solid text-gray-600 text-sm"
              dateFormat="dd MMM yyyy"
              isRangeDatePicker={true}
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <Card>
        <CardHeader>
          <div>Total</div>
        </CardHeader>
        <CardBody>
          <div className="grid grid-cols-2 gap-4">
            {/*  <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
            <div className="flex gap-5 items-center">
              <div className="bg-[#D6FFE8] rounded-full p-2">
                <GiReceiveMoney className="text-xl text-[#00BD52]" />
              </div>
              <span className="text-lg">Total Dana Belum Tersalurkan</span>
            </div>
            <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
              <span className="text-2xl font-semibold">
                Rp. {convertMoney(4200000000)}
              </span>
            </div>
          </div>*/}
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6 my-3">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GiMoneyStack className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-lg">Total Komitmen</span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  Rp.{" "}
                  {convertMoney(
                    totalCommitmentData?.total_commitment
                      ? totalCommitmentData?.total_commitment
                      : 0
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-[#F9FAFB] rounded-xl">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GrDocumentPerformance className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-lg font-semibold">
                  Total Dana Disalurkan
                </span>
              </div>
              <div ref={Total1DonutChartRef}></div>
            </div>
            <div className="p-4 bg-[#F9FAFB] rounded-xl">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GrDocumentPerformance className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-lg font-semibold">
                  Total Dana Dilaporkan
                </span>
              </div>
              {totalReportedFundData?.detail_projects?.length > 0 ? (
                <DonutChart
                  data={totalReportedFundData?.detail_projects.reduce(
                    (data, value) => {
                      data[value.project_name] = value.total_fund;
                      return data;
                    },
                    {}
                  )}
                />
              ) : (
                <span class="italic text-slate-400">Data tidak tersedia</span>
              )}
            </div>
            <div className="p-4 bg-[#F9FAFB] rounded-xl">
              <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
                <div className="flex gap-5 items-center">
                  <div className="bg-[#D6FFE8] rounded-full p-2">
                    <RiExchangeFundsLine className="text-xl text-[#00BD52]" />
                  </div>
                  <span className="text-lg">Selisih Dana Salur-Lapor</span>
                </div>
                <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                  <span className="text-2xl font-semibold">
                    Rp. {convertMoney(1924000000)}
                  </span>
                </div>
              </div>
              <div ref={Total3DonutChartRef}></div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div>Penyaluran vs Pelaporan</div>
        </CardHeader>
        <CardBody>
          {/* <div className="grid grid-cols-2 gap-4 mb-4">
            <CardForm label={`Tahunan`}>
              <div ref={TahunanChartRef}></div>
            </CardForm>
            <CardForm label={`Bulanan`}>
              <div ref={BulananChartRef}></div>
            </CardForm>
          </div> */}
          <div className="flex justify-between px-2 items-center my-4">
            <div className="text-xl font-medium">Filter</div>
            <div className="flex gap-3 space-w-4 w-[calc(100vw/4)]">
              <div className="flex-1 w-1/4">
                <CustomSelect
                  optionsData={[
                    { label: "Proyek", value: "Proyek" },
                    { label: "World Bank", value: "World Bank" },
                  ]}
                  placeholder="Status"
                  className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
                  value={{ label: "Proyek", value: "Proyek" }}
                />
              </div>
              <div className="flex-1 w-1/4">
                <CustomSelect
                  optionsData={[
                    { label: "2022-2023", value: "2022-2023" },
                    { label: "2023-2024", value: "2023-2024" },
                  ]}
                  placeholder="Status"
                  className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
                  value={{ label: "2023-2024", value: "2023-2024" }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GrDocumentPerformance className="text-lg text-[#00BD52]" />
                </div>
                <span className="text-lg">Total Disalurkan</span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  {" "}
                  Rp. {convertMoney(1924000000)}
                </span>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GrDocumentStore className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-lg">Total Dilaporkan</span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  {" "}
                  Rp. {convertMoney(2933000000)}
                </span>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
              <div className="flex gap-5 items-center">
                <div className="bg-[#D6FFE8] rounded-full p-2">
                  <GiPayMoney className="text-xl text-[#00BD52]" />
                </div>
                <span className="text-lg">Selisih Nilai Salur dan Lapor</span>
              </div>
              <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                <span className="text-2xl font-semibold">
                  {" "}
                  Rp. {convertMoney(1019000000)}
                </span>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {/* <div className="grid grid-cols-2 gap-4">
        <CardForm label={`Daftar Indikator`} className={`bg-white`}>
          <div ref={IndicatorChartRef}></div>
        </CardForm>
        <CardForm label={`Pertumbuhan Kemajuan`} className={`bg-white`}>
          <div ref={PertumbuhanLineRef}></div>
        </CardForm>
      </div> */}

      {/* <Card>
        <CardHeader>
          <div>Theory of Changes</div>
        </CardHeader>
        <CardBody className="bg-[#01A24A] rounded-lg p-5 m-6 flex flex-col gap-5">
          <span className="text-xl text-white font-semibold">Impact</span>
          <div className=" grid grid-cols-2 gap-4">
            <div className="p-4 bg-white rounded-lg">
              <span className="text-xl font-semibold">% Progress Capaian</span>
              <div ref={ProgressChartRef}></div>
            </div>
            <div className="p-4 bg-white rounded-lg">
              <span className="text-xl font-semibold">
                Dana yang Disalurkan
              </span>
              <div ref={DanaRef}></div>
            </div>
          </div>
        </CardBody>
        <div className="grid grid-cols-2 gap-4 m-6">
          <CardForm label={`Outcome`} className={`bg-white`} chip={`80%`}>
            <div ref={OutcomeRef}></div>
          </CardForm>
          <CardForm label={`Output`} className={`bg-white`} chip={`19%`}>
            <div ref={OutputRef}></div>
          </CardForm>
          <CardForm label={`Sub Output`} className={`bg-white`} chip={`80%`}>
            <div ref={SubOutputRef}></div>
          </CardForm>
          <CardForm label={`Output Proyek`} className={`bg-white`} chip={`19%`}>
            <div ref={OutputProyekRef}></div>
          </CardForm>
        </div>
      </Card> */}
      <div className="grid grid-cols-2 gap-4 m-4">
        <CardForm label={`Konsep Note`} className={`bg-white`}>
          <div className="w-full bg-[#01A24A] mb-4 p-4 rounded-xl text-white">
            <div className="w-full bg-[#4DBE80] p-4 rounded-xl text-white border border-white">
              <p>Total</p>
              <p className="text-xl font-bold">
                {cfpConceptNotesData?.note_concept?.reduce(
                  (sum, value) => sum + value.count,
                  0
                )}
              </p>
            </div>
          </div>
          {cfpConceptNotesData?.note_concept?.length > 0 ? (
            <DonutChart
              data={cfpConceptNotesData?.note_concept.reduce((data, value) => {
                data[value._id] = value.count;
                return data;
              }, {})}
              params={{}}
            />
          ) : (
            <span class="italic text-slate-400">Data tidak tersedia</span>
          )}
        </CardForm>
        <CardForm label={`Full Proposal`} className={`bg-white`}>
          <div className="w-full bg-[#01A24A] mb-4 p-4 rounded-xl text-white">
            <div className="w-full bg-[#4DBE80] p-4 rounded-xl text-white border border-white">
              <p>Total</p>
              <p className="text-xl font-bold">
                {cfpConceptNotesData?.call_for_proposal?.reduce(
                  (sum, value) => sum + value.count,
                  0
                )}
              </p>
            </div>
          </div>
          {cfpConceptNotesData?.call_for_proposal?.length > 0 ? (
            <DonutChart
              data={cfpConceptNotesData?.call_for_proposal.reduce(
                (data, value) => {
                  data[value._id] = value.count;
                  return data;
                },
                {}
              )}
              params={{}}
            />
          ) : (
            <span class="italic text-slate-400">Data tidak tersedia</span>
          )}
        </CardForm>
        <CardForm label={`Beneficiaries`} className={`bg-white`}>
          {userBeneficiariesData?.length > 0 ? (
            <BarChart
              data={userBeneficiariesData.reduce((data, value) => {
                data[value._id] = value.count;
                return data;
              }, {})}
            />
          ) : (
            <span class="italic text-slate-400">Data tidak tersedia</span>
          )}
        </CardForm>
        <CardForm label={`Team Proyek`} className={`bg-white`}>
          {!isEmpty(projectTeamsData) ? (
            <DonutChart
              data={projectTeamsData}
              params={{ legendValue: true }}
            />
          ) : (
            <span class="italic text-slate-400">Data tidak tersedia</span>
          )}
        </CardForm>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
          <div className="flex gap-5 items-center">
            <div className="bg-[#D6FFE8] rounded-full p-2">
              <IoDocument className="text-lg text-[#00BD52]" />
            </div>
            <span className="text-lg">Lemtara</span>
          </div>
          <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
            <span className="text-2xl font-semibold">50</span>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
          <div className="flex gap-5 items-center">
            <div className="bg-[#D6FFE8] rounded-full p-2">
              <MdGroups className="text-xl text-[#00BD52]" />
            </div>
            <span className="text-lg">Implementing Partner</span>
          </div>
          <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
            <span className="text-2xl font-semibold">99</span>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
          <div className="flex gap-5 items-center">
            <div className="bg-[#D6FFE8] rounded-full p-2">
              <MdOutlineMan2 className="text-xl text-[#00BD52]" />
            </div>
            <span className="text-lg">Laki-laki</span>
          </div>
          <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
            <span className="text-2xl font-semibold">293</span>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6">
          <div className="flex gap-5 items-center">
            <div className="bg-[#D6FFE8] rounded-full p-2">
              <MdOutlineWoman2 className="text-xl text-[#00BD52]" />
            </div>
            <span className="text-lg">Perempuan</span>
          </div>
          <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
            <span className="text-2xl font-semibold">420</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
